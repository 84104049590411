import THEME_COLORS from './theme-colors';
export const THEMES = {

  'default': [
    { group: 'background',                           ...THEME_COLORS.BACKGROUND_LIGHT_GREY },
    { group: 'geometry.study.existing',              ...THEME_COLORS.STUDY_DARK_GREY },
    { group: 'geometry.study.proposed',              ...THEME_COLORS.STUDY_DARK_GREY },
    { group: 'geometry.study.future',                ...THEME_COLORS.STUDY_DARK_GREY },
    { group: 'geometry.study',                       ...THEME_COLORS.STUDY_DARK_GREY },
    { group: 'geometry.surround.existing',           ...THEME_COLORS.SURROUND_VERY_DARK_GREY },
    { group: 'geometry.surround.proposed',           ...THEME_COLORS.SURROUND_VERY_DARK_GREY },
    { group: 'geometry.surround.future',             ...THEME_COLORS.SURROUND_VERY_DARK_GREY },
    { group: 'geometry.surround',                    ...THEME_COLORS.SURROUND_VERY_DARK_GREY },
    { group: 'geometry.landscaping.existing',        ...THEME_COLORS.SURROUND_VERY_DARK_GREY },
    { group: 'geometry.landscaping.proposed',        ...THEME_COLORS.STUDY_DARK_GREY },
    { group: 'geometry.landscaping',                 ...THEME_COLORS.STUDY_DARK_GREY },
    { group: 'geometry.inner ground.existing',       ...THEME_COLORS.DEFAULT_GROUND_GREY },
    { group: 'geometry.inner ground',                ...THEME_COLORS.DEFAULT_GROUND_GREY },
    { group: 'geometry.outer ground.existing',       ...THEME_COLORS.DEFAULT_GROUND_GREY },
    { group: 'geometry.outer ground',                ...THEME_COLORS.DEFAULT_GROUND_GREY },
    { group: 'geometry',                             ...THEME_COLORS.DEFAULT_GROUND_GREY },
    { group: 'geometry.terrain.existing',            ...THEME_COLORS.DEFAULT_GROUND_GREY },
    { group: 'geometry.terrain',                     ...THEME_COLORS.DEFAULT_GROUND_GREY },
    { group: 'geometry.mitigation.v1',               ...THEME_COLORS.STUDY_DARK_GREY },
    { group: 'geometry.mitigation.v2',               ...THEME_COLORS.STUDY_DARK_GREY },
    { group: 'geometry.mitigation',                  ...THEME_COLORS.STUDY_DARK_GREY },
    { group: 'geometry.presentation plane',          ...THEME_COLORS.PLANE_HOT_PINK_25 },
    { group: 'geometry.usage plane',                 ...THEME_COLORS.PLANE },
    { group: 'geometry.overlay',                     ...THEME_COLORS.DARK_GREY },
  ],
  'light': [
    { group: 'background',                           ...THEME_COLORS.BACKGROUND_WHITE },
    { group: 'geometry.study.existing',              ...THEME_COLORS.STUDY_LIGHT_GREY },
    { group: 'geometry.study.proposed',              ...THEME_COLORS.STUDY_LIGHT_GREY },
    { group: 'geometry.study.future',                ...THEME_COLORS.STUDY_LIGHT_GREY },
    { group: 'geometry.study',                       ...THEME_COLORS.STUDY_LIGHT_GREY },
    { group: 'geometry.surround.existing',           ...THEME_COLORS.SURROUND_MEDIUM_GREY },
    { group: 'geometry.surround.proposed',           ...THEME_COLORS.SURROUND_MEDIUM_GREY },
    { group: 'geometry.surround.future',             ...THEME_COLORS.SURROUND_MEDIUM_GREY },
    { group: 'geometry.surround',                    ...THEME_COLORS.SURROUND_MEDIUM_GREY },
    { group: 'geometry.landscaping.existing',        ...THEME_COLORS.SURROUND_MEDIUM_GREY },
    { group: 'geometry.landscaping.proposed',        ...THEME_COLORS.TRANSLUCENT_LIGHT_GREY },
    { group: 'geometry.landscaping',                 ...THEME_COLORS.TRANSLUCENT_LIGHT_GREY },
    { group: 'geometry.inner ground.existing',       ...THEME_COLORS.LIGHT_GROUND_GREY },
    { group: 'geometry.inner ground',                ...THEME_COLORS.LIGHT_GROUND_GREY },
    { group: 'geometry.outer ground.existing',       ...THEME_COLORS.LIGHT_GROUND_GREY },
    { group: 'geometry.outer ground',                ...THEME_COLORS.LIGHT_GROUND_GREY },
    { group: 'geometry',                             ...THEME_COLORS.LIGHT_GROUND_GREY },
    { group: 'geometry.terrain.existing',            ...THEME_COLORS.LIGHT_GROUND_GREY },
    { group: 'geometry.terrain',                     ...THEME_COLORS.LIGHT_GROUND_GREY },
    { group: 'geometry.mitigation.v1',               ...THEME_COLORS.TRANSLUCENT_LIGHT_GREY },
    { group: 'geometry.mitigation.v2',               ...THEME_COLORS.TRANSLUCENT_LIGHT_GREY },
    { group: 'geometry.mitigation',                  ...THEME_COLORS.TRANSLUCENT_LIGHT_GREY },
    { group: 'geometry.presentation plane',          ...THEME_COLORS.PLANE_25 },
    { group: 'geometry.usage plane',                 ...THEME_COLORS.PLANE },
    { group: 'geometry.overlay',                     ...THEME_COLORS.DARK_GREY },
  ],
  'translucent': [
    { group: 'background',                           ...THEME_COLORS.LIGHT_GROUND_GREY },
    { group: 'geometry.study.existing',              ...THEME_COLORS.TRANSLUCENT_MEDIUM_GREY },
    { group: 'geometry.study.proposed',              ...THEME_COLORS.TRANSLUCENT_LIGHT_GREY },
    { group: 'geometry.study.future',                ...THEME_COLORS.TRANSLUCENT_LIGHT_GREY },
    { group: 'geometry.study',                       ...THEME_COLORS.TRANSLUCENT_LIGHT_GREY },
    { group: 'geometry.surround.existing',           ...THEME_COLORS.TRANSLUCENT_MEDIUM_GREY },
    { group: 'geometry.surround.proposed',           ...THEME_COLORS.TRANSLUCENT_MEDIUM_GREY },
    { group: 'geometry.surround.future',             ...THEME_COLORS.TRANSLUCENT_MEDIUM_GREY },
    { group: 'geometry.surround',                    ...THEME_COLORS.TRANSLUCENT_MEDIUM_GREY },
    { group: 'geometry.landscaping.existing',        ...THEME_COLORS.TRANSLUCENT_MEDIUM_GREY },
    { group: 'geometry.landscaping.proposed',        ...THEME_COLORS.TRANSLUCENT_VEGETATION_GREEN },
    { group: 'geometry.landscaping',                 ...THEME_COLORS.TRANSLUCENT_VEGETATION_GREEN },
    { group: 'geometry.inner ground.existing',       ...THEME_COLORS.TRANSLUCENT_GROUND_GREY },
    { group: 'geometry.inner ground',                ...THEME_COLORS.TRANSLUCENT_GROUND_GREY },
    { group: 'geometry.outer ground.existing',       ...THEME_COLORS.TRANSLUCENT_GROUND_GREY },
    { group: 'geometry.outer ground',                ...THEME_COLORS.TRANSLUCENT_GROUND_GREY },
    { group: 'geometry',                             ...THEME_COLORS.TRANSLUCENT_GROUND_GREY },
    { group: 'geometry.terrain.existing',            ...THEME_COLORS.TRANSLUCENT_GROUND_GREY },
    { group: 'geometry.terrain',                     ...THEME_COLORS.TRANSLUCENT_GROUND_GREY },
    { group: 'geometry.mitigation.v1',               ...THEME_COLORS.TRANSLUCENT_MITIGATION_MAGENTA },
    { group: 'geometry.mitigation.v2',               ...THEME_COLORS.TRANSLUCENT_MITIGATION_MAGENTA },
    { group: 'geometry.mitigation',                  ...THEME_COLORS.TRANSLUCENT_MITIGATION_MAGENTA },
    { group: 'geometry.presentation plane',          ...THEME_COLORS.PLANE_25 },
    { group: 'geometry.usage plane',                 ...THEME_COLORS.PLANE },
    { group: 'geometry.overlay',                     ...THEME_COLORS.DARK_GREY },
  ],
  'geometry': [
    { group: 'background',                           ...THEME_COLORS.BACKGROUND_WHITE },
    { group: 'geometry.study.existing',              ...THEME_COLORS.STUDY_YELLOW },
    { group: 'geometry.study.proposed',              ...THEME_COLORS.STUDY_RED },
    { group: 'geometry.study.future',                ...THEME_COLORS.STUDY_ORANGE },
    { group: 'geometry.study',                       ...THEME_COLORS.STUDY_RED },
    { group: 'geometry.surround.existing',           ...THEME_COLORS.SURROUND_MEDIUM_GREY },
    { group: 'geometry.surround.proposed',           ...THEME_COLORS.SURROUND_BLUE },
    { group: 'geometry.surround.future',             ...THEME_COLORS.SURROUND_BLUE },
    { group: 'geometry.surround',                    ...THEME_COLORS.SURROUND_MEDIUM_GREY },
    { group: 'geometry.landscaping.existing',        ...THEME_COLORS.SURROUND_MEDIUM_GREY },
    { group: 'geometry.landscaping.proposed',        ...THEME_COLORS.VEGETATION_GREEN },
    { group: 'geometry.landscaping',                 ...THEME_COLORS.VEGETATION_GREEN },
    { group: 'geometry.inner ground.existing',       ...THEME_COLORS.LIGHT_GROUND_GREY },
    { group: 'geometry.inner ground',                ...THEME_COLORS.LIGHT_GROUND_GREY },
    { group: 'geometry.outer ground.existing',       ...THEME_COLORS.LIGHT_GROUND_GREY },
    { group: 'geometry.outer ground',                ...THEME_COLORS.LIGHT_GROUND_GREY },
    { group: 'geometry',                             ...THEME_COLORS.LIGHT_GROUND_GREY },
    { group: 'geometry.terrain.existing',            ...THEME_COLORS.LIGHT_GROUND_GREY },
    { group: 'geometry.terrain',                     ...THEME_COLORS.LIGHT_GROUND_GREY },
    { group: 'geometry.mitigation.v1',               ...THEME_COLORS.MITIGATION_MAGENTA },
    { group: 'geometry.mitigation.v2',               ...THEME_COLORS.MITIGATION_MAGENTA },
    { group: 'geometry.mitigation',                  ...THEME_COLORS.MITIGATION_MAGENTA },
    { group: 'geometry.presentation plane',          ...THEME_COLORS.PLANE_TRANSPARENT_WHITE_70 },
    { group: 'geometry.usage plane',                 ...THEME_COLORS.PLANE },
    { group: 'geometry.overlay',                     ...THEME_COLORS.DARK_GREY },
  ],
  'dark': [
    { group: 'background',                           ...THEME_COLORS.BACKGROUND_DARK_GREY },
    { group: 'geometry.study.existing',              ...THEME_COLORS.STUDY_MEDIUM_GREY },
    { group: 'geometry.study.proposed',              ...THEME_COLORS.STUDY_MEDIUM_GREY },
    { group: 'geometry.study.future',                ...THEME_COLORS.STUDY_MEDIUM_GREY },
    { group: 'geometry.study',                       ...THEME_COLORS.STUDY_MEDIUM_GREY },
    { group: 'geometry.surround.existing',           ...THEME_COLORS.SURROUND_DARK_GREY },
    { group: 'geometry.surround.proposed',           ...THEME_COLORS.SURROUND_DARK_GREY },
    { group: 'geometry.surround.future',             ...THEME_COLORS.SURROUND_DARK_GREY },
    { group: 'geometry.surround',                    ...THEME_COLORS.SURROUND_DARK_GREY },
    { group: 'geometry.landscaping.existing',        ...THEME_COLORS.SURROUND_DARK_GREY },
    { group: 'geometry.landscaping.proposed',        ...THEME_COLORS.TRANSLUCENT_MEDIUM_GREY },
    { group: 'geometry.landscaping',                 ...THEME_COLORS.TRANSLUCENT_MEDIUM_GREY },
    { group: 'geometry.inner ground.existing',       ...THEME_COLORS.DARK_GROUND_GREY },
    { group: 'geometry.inner ground',                ...THEME_COLORS.DARK_GROUND_GREY },
    { group: 'geometry.outer ground.existing',       ...THEME_COLORS.DARK_GROUND_GREY },
    { group: 'geometry.outer ground',                ...THEME_COLORS.DARK_GROUND_GREY },
    { group: 'geometry',                             ...THEME_COLORS.DARK_GROUND_GREY },
    { group: 'geometry.terrain.existing',            ...THEME_COLORS.DARK_GROUND_GREY },
    { group: 'geometry.terrain',                     ...THEME_COLORS.DARK_GROUND_GREY },
    { group: 'geometry.mitigation.v1',               ...THEME_COLORS.TRANSLUCENT_MEDIUM_GREY },
    { group: 'geometry.mitigation.v2',               ...THEME_COLORS.TRANSLUCENT_MEDIUM_GREY },
    { group: 'geometry.mitigation',                  ...THEME_COLORS.TRANSLUCENT_MEDIUM_GREY },
    { group: 'geometry.presentation plane',          ...THEME_COLORS.PLANE_HOT_PINK_25 },
    { group: 'geometry.usage plane',                 ...THEME_COLORS.PLANE },
    { group: 'geometry.overlay',                     ...THEME_COLORS.DARK_GREY }
  ],
};

export const THEME_ICONS = {
  'default': '/theme_icons/Default.png',
  'light': '/theme_icons/Light.png',
  'translucent': '/theme_icons/Translucent.png',
  'geometry': '/theme_icons/Geometry.png',
  'dark': '/theme_icons/Dark.png',
  'default-dd': '/theme_icons/Default-dd.png',
  'light-dd': '/theme_icons/Light-dd.png',
  'translucent-dd': '/theme_icons/Translucent-dd.png',
  'geometry-dd': '/theme_icons/Geometry-dd.png',
  'dark-dd': '/theme_icons/Dark-dd.png',
  'default-1': '/theme_icons/Default 1.png',
  'light-1': '/theme_icons/Light 1.png',
  'translucent-1': '/theme_icons/Translucent 1.png',
  'geometry-1': '/theme_icons/Geometry 1.png',
  'dark-1': '/theme_icons/Dark 1.png',
  '1980s Computer Movie-1': '/theme_icons/Tron 1.png',
};

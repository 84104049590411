<template>
  <div id="app" class="app" :class="this.authenticationState">
    <router-link :to="{ name: 'AppContainer' }"></router-link>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { AUTHENTICATED } from '@/store/statuses/auth';

export default {
  name: 'App',
  computed: {
    authenticationState() {
      return this.authState.status === AUTHENTICATED &&  !this.isQuestionnaireOn? 'authenticated' : 'unauthenticated';
    },
    ...mapGetters(['authState', 'isQuestionnaireOn'])
  }
};
</script>

<style>

/* Vue formulate adds an arrow to dropdown elements by creating a paritally visible border created via a css ::before selector.  This arrow is not clickable, which makes the 
dropdown control a little awkward.  Appending pointer-events: none to the css that creates the arrow causes the click event to pass through it to the layer below */
.formulate-input-element--select::before {
  pointer-events: none;  
}

.formulate-input-element::before {
  pointer-events: none;  
}


/* Don't apply the top margin on the login page */
.app.unauthenticated {
  margin: 0;
}

/* allow the default of 1.5rem in the global config from the RWDI_Widgets project to be turned off on the specified element and all decendants */
.no-top-margin-all-descendants {
	margin-top: 0;
}
.no-top-margin-all-descendants * {
	margin-top: 0;
}
/*  -------------------- */

/* Otherwise the default styling applies */
@import '~rwdi-widgets/dist/rwdi-widgets.css';
@import '~@braid/vue-formulate/dist/snow.min.css';

body {
  height: 100%;
  background-color: var(--grey-300);
}

/* Override to prevent bug regarding fixed header margin when different font sizes are selected in browser */
/* * but keeping the margin-top:53px from rwdi-widgets.css import */
.app{
  /* margin-top: 3.313rem; */
  margin-top:62.4px
}

@keyframes spCircRot {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(358deg);
  }
}

/* allow the default of 1.5rem in the global config from the RWDI_Widgets project to be turned off on the specified element and all decendants */
.no-top-margin-all-descendants {
	margin-top: 0;
}
.no-top-margin-all-descendants * {
	margin-top: 0;
}

/* Uncomment the below line to help debugging of elements which are flowing off the screen */
/* * { outline: solid 0.25rem rgba(255, 0, 0, 0.5); outline-offset: -0.25rem; } */
</style>
